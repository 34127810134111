import './App.css';
import React from 'react';

import AppContainer from './components/appContainer/appContainer';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.scrolling = this.scrolling.bind(this);
    this.playIfInHotSpot = this.playIfInHotSpot.bind(this);

    this.isTouchDevice = matchMedia('(hover: none)').matches;
    console.log("this.isTouchDevice: ", this.isTouchDevice);

    this.linkRefs = ['essenceLinkRef', 'quesjinsLinkRef', 'litIntentLinkRef'];

    for (let i = 0; i < this.linkRefs.length; i++) {
      this[this.linkRefs[i]] = React.createRef();
    }

  }

  playIfInHotSpot(ref){
    console.log("trying this ref: ", ref);
    let refPos = this[ref].current.linkContainerDivRef.getBoundingClientRect();
    console.log("ref: ", refPos);
    if (refPos.top > 50 && refPos.top < 100){
      this[ref].current.play_linkContainer();
    } else {
      this[ref].current.pause_linkContainer();
    }
  }


  scrolling(ev){
    console.log("scrolling ev: ", this.linkRefs);

    // console.log("this.mainContainerRef.scrollTop: ", this.mainContainerRef.scrollTop);
    // let elsToCheck = this.mainContainerRef.querySelectorAll('.linkContainer');
    // console.log("elsToCheck: ", elsToCheck);
    // for (let i = 0; i < elsToCheck.length; i++) {
    //   let item = elsToCheck[i];
    //   this.isElementInHotSpot(item, i);
    // }
    for (let i = 0; i < this.linkRefs.length; i++) {
      console.log("checking if should play ", this.linkRefs[i]);
      this.playIfInHotSpot(this.linkRefs[i]);
    }
 


  }


  render() {
    console.log("rendering react app!");

    return (
      <div id="mainContainer" className="maxSpace" 
        ref={c => (this.mainContainerRef = c)}
        onScroll={this.isTouchDevice ? this.scrolling : undefined }>
        <div id="introSection" className="sectionContainer">
          <h2 className="header">About CognitionMint</h2>

          <div id="introContainer">
            <div className="blurb">
              CognitionMint is an early stage startup, striving to deliver apps that enrich human cognition.
            </div>

          </div>
        </div>

        <div id="appsSection" className="sectionContainer">

          <div id="allLinksContainer" className="maxSpace"
            >

            <AppContainer
              appName='EssenceOfTheQuote'
              appBlurb='EssenceOfTheQuote is a Quote feed app that curates the essential thought of a quote and integrates
          with Twitter metrics.'
              appSlogan='Learn and evaluate the essentials of wisdom.'
              isTouchDevice={this.isTouchDevice}
              ref={this.essenceLinkRef}
            />

            <AppContainer
              appName='Quesjins'
              appHeader={<><span className="title">Ques</span><span className="titleItalics">ji</span><span className="title">ns</span></>}
              appBlurb='Quesjins provides you with questions to help focus, think about thinking, consider opportunities
            and solve problems.'
              appSlogan='Ask big Questions. Grow your thinking skillset.'
              isTouchDevice={this.isTouchDevice}
              ref={this.quesjinsLinkRef}
            />




            <AppContainer
              appName='LitIntent'
              appHeader={<><div id="litHeading" className="headingText">lit</div>
                <div id="intentHeading" className="headingText">intent</div></>}
              appBlurb='Litintent empowers people to examine and direct their lives with greater awareness to boost motivation
            + improve planning.'
              appSlogan='Graph motivations and plans. Make meaning.'
              isTouchDevice={this.isTouchDevice}
              ref={this.litIntentLinkRef}
            />


          </div>


          <div id="footerSection">
            <div id="footerContainer">
              Like to partner? It'll be great to connect with you<br />
              <a href="mailto:hi@cognitionmint.com">hi@cognitionmint.com</a>
            </div>
            <div className="linkSpacer"></div>
            <div className="linkSpacer"></div>
          </div>
        </div>

      </div>
    );
  }
}

export default App;
