
import React from 'react';
import './appContainer.css'


class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.linkContainer_onMouseOver = this.linkContainer_onMouseOver.bind(this);
    this.linkContainer_onMouseOut = this.linkContainer_onMouseOut.bind(this);

    this.linkContainer_onPointerDown = this.linkContainer_onPointerDown.bind(this);
    this.linkContainer_onPointerUp = this.linkContainer_onPointerUp.bind(this);

    this.play_linkContainer = this.play_linkContainer.bind(this);
    this.pause_linkContainer = this.pause_linkContainer.bind(this);

  }


  componentDidMount() {
    console.log("searchbar comp did mount with el: ", this.refSearchInput);

  }

  componentDidUpdate() {

  }


  play_linkContainer() {
    if (this.isPlaying) return;
    this.isPlaying = true;
    this.imgRef.classList.add('hidden');
    this.videoRef.classList.remove('hidden');
    this.videoRef.play();


  }

  pause_linkContainer() {
    if (!this.isPlaying) return;
    this.isPlaying = false;
    this.imgRef.classList.remove('hidden');
    this.videoRef.classList.add('hidden');
    this.videoRef.pause();
  }

  linkContainer_onMouseOver(ev) {
    console.log("mousing over: ", ev);
    this.play_linkContainer();

  }


  linkContainer_onMouseOut(ev) {
    console.log("mousing OUT: ", ev);
    this.pause_linkContainer();
  }


  linkContainer_onPointerDown(ev) {
    console.log("onPointerDown: ", ev);
    this.play_linkContainer();

  }

  linkContainer_onPointerUp(ev) {
    console.log("onPointerUp: ", ev);
    this.pause_linkContainer();
  }


  render() {
    const { appName, appBlurb, appSlogan, isTouchDevice } = this.props;
    const imgSrc = `./img/apps/${appName}.png`;
    const webMsrc = `./vid/${appName}.webm`;
    const mp4src = `./vid/${appName}.mp4`;
    const appLink = `https://${appName}.app`;
    const appHeader = this.props.appHeader || appName;

    return (
      <>
        <a ref={c => (this.linkContainerDivRef = c)} id={appName} href={appLink} className="linkContainer" 
          onMouseOver={isTouchDevice ? undefined : this.linkContainer_onMouseOver}
          onMouseOut={isTouchDevice ? undefined : this.linkContainer_onMouseOut}>
          <div className="linkHeader">
            {appHeader}
          </div>
          <div className="appImage ">
            <img src={imgSrc} className="" width="100%"
              ref={c => (this.imgRef = c)}/>
            <video ref="video"  width="100%" className="hidden" preload="auto" loop={true}
            muted={true} autoPlay ref={c => (this.videoRef = c)}>
              <source src={webMsrc} type="video/webm" />
              <source src={mp4src} type="video/mp4" />
            </video>
          </div>
          <div className="appBlurb">
            {appBlurb}<br /><br />
            {appSlogan}<br />
          </div>
        </a>
        <div className="linkSpacer"></div>
      </>


    )}
}

        export default AppContainer;



